import { makeAutoObservable, runInAction } from 'mobx';

export default class headerStore {

    counterNextStories = 100;

    bigHeader = true;
    curHeader = 0;
    maxHeader = 3;

    progress = 0;	
    speedInstaProgress = 50;


    arrHeaders = Array.from(Array(this.maxHeader + 1).keys());

    switchBigSizeHeadert = () => {
        this.bigHeader = !this.bigHeader;
    }
    
    curHeaderUndo = () => {
        --this.curHeader;
        if (this.curHeader < 0)
            runInAction(() => {
                this.progress = 0;
                this.curHeader = this.maxHeader;
            });
    }
    curHeaderNext = ()=> {
        ++this.curHeader;
        if (this.curHeader > this.maxHeader)
            runInAction(() => {
                this.progress = 0;
                this.curHeader = 0;
            });
    }

    runProgress = () => {
        // this.progress =0;
        
        this.interval = setInterval(() => {
            ++this.progress;
            if(this.progress == 65){
                this.progress = 0;
                this.curHeaderNext();
            }
        }, 100);

    }
    
    
constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.runProgress();
    const queryParameters = new URLSearchParams(window.location.search);
    const headerNumber = queryParameters.get("h");
    // console.log(type);
    if(this.isNumber(headerNumber) && (headerNumber > 0) && (headerNumber <= this.maxHeader))
        this.curHeader = headerNumber
    // const name = queryParameters.get("name")
  }

  isNumber = (n) => { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

}