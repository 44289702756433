import React from "react";
import { Row } from "react-bootstrap";
import './index.css';



const reviews = () => {

	const allReviews = ['IMG_7417 2','IMG_7418 2','IMG_7419 2','IMG_7594 2','IMG_7727 2','IMG_7928', 'IMG_7730 2'];
	return (
		<div>
			<h3>Отзывы</h3>
			<label>Лучшие отзывы это сообщения от моих пациентов!</label>
			<br/>
			<Row className="mt-5">
				{allReviews.map((review, index) => {
					return (
						<div className="review-block p-1" key={index}>
							<img className="review" src={'./imgs/' + review + '.jpg'} />
						</div>
					)})
				}
			</Row>
		</div>
	);
}

export default reviews
