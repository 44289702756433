import React from "react";
import './index.css';

const priceLine = (props) => {

	return (
		<div className="priceLine" > 
        	 {props.serviceName}  <b className="ml-auto mr-0">{props.fix ? "" :'от'} {props.price} руб.</b> {props.remark ? "*" : ''}
		</div>
	);
}

export default priceLine