import React, { Component } from "react";
import './index.css';

import { observer, makeAutoObservable } from 'mobx-react';
import {  runInAction } from 'mobx';
import { StoreContext } from '../../contexts'

import { useState, useEffect } from 'react'

import { render } from "react-dom";
import MainStore from "../../store/mainStore";
// import  from 'react'
import Scroll from 'react-scroll';


import RYABININA_SUMMER_img from '../../imgs/IMG1222-3.jpg';
import mapMSK from '../../imgs/map_msk.jpg';


const ScrollLink = Scroll.ScrollLink;








const Header = () => {

	const store = React.useContext(StoreContext);
	const { headerStore } = store;

	function mainAction () {
		const anchor = document.querySelector('#contactBlock')
		anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
	}

  
	function getIndicatorProgress () {
		if( (headerStore.progress >= headerStore.speedInstaProgress))
			return 100;
		else 
			return (headerStore.progress % headerStore.speedInstaProgress + 1)/headerStore.speedInstaProgress * 100;
	}
	return (
		<div className="header">
			<div className="row p-0 m-0 scrollableRow">
				<div className={"cloudWelcome mainSvetaBlack "
								+ (headerStore.bigHeader ? " bigBanner ": "")
								+ (headerStore.curHeader == 0 ? "  ": " lightOpacity")} 
						hidden={headerStore.curHeader != 0} >
					<div className="column p-0 col-4">
						<img src={"./imgs/IMG_50681.jpg"} className="headerPoster  center-absolute-mobile "/>	
					</div>
		
					<div className={"column mobileCenter min380px p-3 col-5 ml-auto z3  " + (headerStore.bigHeader ? " mr-0" :"  mr-100-desktop")}>
						<h1 className="m-auto mb-0 z3" >Ортодонтия - здоровые зубы и красивая эстетика лица</h1>
						<h4 className=" mt-3 mb-0 z3">Кудрявцева Светлана Андреевна</h4>
						<h6 className=" mt-1 m-auto z3">Врач-ортодонт</h6>
						{/* <h1 className="m-auto mb-0 z3" >Кудрявцева Светлана Андреевна</h1> */}
						{/* <h6 className=" mt-3 m-auto z3">Врач-ортодонт</h6> */}

						
						<button variant="primary  z3" onClick={mainAction}>Записаться на консультацию</button>
					</div>
					<div className="backgroupOpacity bottomToTopBlack" style={{
								height: '400px',
								width: 'calc(100% + 24px)',
								bottom: '0px',
								margin: '-12px',
								opacity: '0'
							}}>   </div>
				</div>
				<div className={"cloudWelcome mainSveta "
								+ (headerStore.bigHeader ? " bigBanner ": "")
								+ (headerStore.curHeader == 1 ? "  ": " lightOpacity")} 
						hidden={headerStore.curHeader != 1} >
					<div className="column p-0 col-4">
						<img src={"./imgs/IMG_18024.jpg"} className="headerPoster "/>	
					</div>
		
					<div className={"column mobileCenter min380px p-3 col-5 ml-auto z3  " + (headerStore.bigHeader ? " mr-0" :"  mr-100-desktop")}>
						{/* <h1 className="m-auto mb-0 z3" ></h1> */}
						<h1 className="m-auto mb-0 z3" >Красивая улыбка - </h1>
						<h1 className="mt-0 mb-0 z3" >не значит дорого</h1>	
						{/* <h1 className="mt-0 mb-0 z3" >не значит дорого</h1> */}
						<h4 className=" mt-3 mb-0 z3">Кудрявцева Светлана Андреевна</h4>
						<h6 className=" mt-3 m-auto z3">Врач-ортодонт</h6>
						{/* <h1 className="m-auto mb-0 z3" >Кудрявцева Светлана Андреевна</h1> */}
						{/* <h6 className=" mt-3 m-auto z3">Врач-ортодонт</h6> */}

						
						<button variant="primary  z3" onClick={mainAction}>Записаться на консультацию</button>
					</div>
					<div className="backgroupOpacity bottomToTop" style={{
								height: '400px',
								width: 'calc(100% + 24px)',
								bottom: '0px',
								margin: '-12px',
								opacity: '0'
							}}>   </div>
				</div>
				
				<div className={"cloudWelcome  RYABININA "
								+ (headerStore.bigHeader ? " bigBanner": "")
								+ (headerStore.curHeader == 2 ? "  ": " lightOpacity")} 
					hidden={headerStore.curHeader != 200} >
			
						<img src={"./imgs/IMG1222.jpg"} className="headerPoster mobileCenterLeft-150px  center-absolute-mobile"/>	
		

					<div className={"column p-3  min360px mobileCenter animSpeed col-5 ml-auto " + (headerStore.bigHeader ? " mr-0" :" mr-100-desktop")}>
						<h1 className="m-auto mb-0" >Новая глава моей жизни</h1>
						<h6 className="mt-3 m-auto">пациент: Рябинина Александра</h6>
						<button variant="primary" onClick={mainAction}>Записаться на консультацию</button>
					</div>	
					<div className="backgroupOpacity bottomToTop" style={{
								height: '300px',
								width: 'calc(100% + 24px)',
								bottom: '0px',
								margin: '-12px',
								opacity: '0'
							}}>   </div>
				</div>
				<div className={"cloudWelcome  RYABININA_SUMMER "
								+ (headerStore.bigHeader ? " bigBanner": "")
								+ (headerStore.curHeader == 2 ? "  ": " lightOpacity")} 
					hidden={headerStore.curHeader != 2}>
			
						<img src={RYABININA_SUMMER_img} className="headerPoster mobileCenterLeft-150px center-absolute-mobile"/>	
		

					<div className={"column p-3  min360px mobileCenter animSpeed col-5 ml-auto " + (headerStore.bigHeader ? " mr-0" :" mr-100-desktop")}>
						<h1 className="m-auto mb-0" >Новая глава моей жизни!</h1>
						<h6 className="mt-3 m-auto">пациент: Рябинина Александра</h6>
						<button variant="primary" onClick={mainAction}>Записаться на консультацию</button>
					</div>	
					<div className="backgroupOpacity bottomToTopBlack" style={{
								height: '300px',
								width: 'calc(100% + 24px)',
								bottom: '0px',
								margin: '-12px',
								opacity: '0'
							}}>   </div>
				</div>	
				<div className={"cloudWelcome  MIKE "
								+ (headerStore.bigHeader ? " bigBanner": "")
								+ (headerStore.curHeader == 3 ? "  ": " lightOpacity")} 
					hidden={headerStore.curHeader != 3}>
						<img src={"./imgs/IMG_8009.jpg"} className="headerPoster  center-absolute-mobile "/>	
					<div className={"column p-3 mobileCenter  min360px animSpeed col-5 ml-auto " + (headerStore.bigHeader ? " mr-3" :" mr-100-desktop")}>
						<h1 className="m-auto mb-0">Уверенность в себе начинается с улыбки</h1>
						<h6 className="mt-3 m-auto">пациент: Михаил Константинович</h6>
						<button variant="primary" onClick={mainAction}>Записаться на консультацию</button>
					</div>	
					<div className="backgroupOpacity bottomToTopBlack" style={{
								height: '400px',
								width: 'calc(100% + 24px)',
								bottom: '0px',
								margin: '-12px',
								opacity: '0'
							}}>   </div>
				</div>	
				{/* <div className={"cloudWelcome  VIDEO_cabinet "
								+ (headerStore.bigHeader ? " bigBanner": "")
								+ (headerStore.curHeader == 93 ? "  ": " lightOpacity")} 
								hidden={headerStore.curHeader != 93}>
					<video autoPlay muted loop>
						<source src="https://твойортодонт.рф/video/cabinet.mp4" type="video/mp4" />
					</video>
					<div className={"column p-3 mobileCenter  min360px animSpeed col-5 mr-auto " + (headerStore.bigHeader ? " mr-3" :" mr-100-desktop")}>
						<h1 className="m-auto mb-0">Уютно как дома</h1>
						<h6 className="mt-3 m-auto">Новый взгляд на стоматологию</h6>
						<button variant="primary" onClick={mainAction}>Записаться на консультацию</button>
					</div>	
					<div className="backgroupOpacity bottomToTopBlack" style={{
								height: '400px',
								width: 'calc(100% + 24px)',
								bottom: '0px',
								margin: '-12px',
								opacity: '0'
							}}>   </div>
						<div className="backgroupOpacity leftToRight" style={{
								height: '120%',
								width: '600px',
								bottom: '0px',
								margin: '-12px',
								opacity: '1'
							}}>   </div>
				</div>	
				 */}

				{/*  <video autoplay muted loop>
        <source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
    </video> */}
				<div className={"cloudWelcome  komarova "
								+ (headerStore.bigHeader ? " bigBanner": "")
								+ (headerStore.curHeader == 10 ? "  ": " lightOpacity")} 
					hidden={headerStore.curHeader != 10}>
			
						<img src={"./imgs/adult2.jpg"} className="headerPoster "/>	
		
					<div className="column p-3 col-5" hidden>
						<h3>Ваша улыбка - ваша точка уверенности!</h3>
						<a href="tg://resolve?domain=+79877587777" target="_blank" className="mt-auto">
							<button variant="primary">Записаться на консультацию</button>
						</a>
					</div>	
					<div className={"column p-3 animSpeed min360px col-5 ml-auto " + (headerStore.bigHeader ? " mr-0" :" mr-mr-100-desktop")}>
					
						<h1 className="m-auto mb-3" >Новая глава моей жизни</h1>
						<h6 className="mt-1 m-auto">пациент: Комарова Светлана</h6>
						<a href="tg://resolve?domain=+79877587777" target="_blank" className="mt-auto">
							<button variant="primary">Записаться на консультацию</button>
						</a>
					</div>	
				</div>		
			</div>
			<div className="row m-0 p-1 mt-1">
				{/* <div className="lineTimeStories"> 		</div> */}
				<div className="row m-0 p-1 instaIndicatiorContainerMain ">
					{headerStore.arrHeaders.map((item,index)=>{
						return <div className="instaIndicatiorContainer" key={index} style={{width: 'calc(' +100/(headerStore.maxHeader + 1)+'% - 16px)'}}>
								<div className="instaIndicatior"  style={{width: '100%'}} hidden={!(headerStore.curHeader>index)}></div>			
								<div className="instaIndicatior"  style={{width: getIndicatorProgress()+'%'}}  hidden={!(headerStore.curHeader==index)}></div>
								<div className="instaIndicatior"  style={{width: '0%'}}  hidden={!(headerStore.curHeader<index)}></div>
							</div>
					})}
				</div>
				<div className=" row ml-1 " style={{width: '170px'}}>
					<button className="btn btn-icons undo ml-auto mr-2" onClick={headerStore.curHeaderUndo}>
						<img src="../../imgs/undo.png"/>
					</button>
					<button className="btn btn-icons updown ml-0 mr-0 hiddenInMobile" onClick={headerStore.switchBigSizeHeadert}>
						<img src="../../imgs/icons8-захват-движения-48.png"/>
					</button>
					<button className="btn btn-icons next ml-2 mr-0" onClick={headerStore.curHeaderNext}>
						<img src="../../imgs/next.png"/>
					</button>
				</div>
			</div>
		</div>
	);
}

export default observer(Header)