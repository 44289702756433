import React from "react";
import './index.css';
import { Row } from "react-bootstrap";
import PriceLine from "./priceLine";

const priceList = (props) => {

	return (
		<div className="priceList p-0 m-0  mt-5 row" > 
            <h3 className="w-100">Ознакомительная информация:</h3>
                <div className="priceList-block row">
                        <b className="w-100 p-3">Красивая улыбка - не значит дорого.</b>
                        <div className="w-100 p-2 ">
                            <PriceLine serviceName="Консультация" fix="true" price="500"/>
                            {/* <PriceLine serviceName="Диагностика" fix="true" price="3 000"/> */}
                            <br/>
                            <b>Брекет системы</b>    
                            <br/>
                            <PriceLine serviceName="Лигатурная металлическая брекет система (Mini Diamond) " price="15 000" remark="true"/>
                            <PriceLine serviceName="Самолигирующая металлическая брекет система (Damon Q2)" price="35 000" remark="true"/>
                            <PriceLine serviceName="Комбинированная брекет система (керамика+металл)" price="45 000" remark="true"/>
                            <PriceLine serviceName="Керамическая брекет система (Damon Clear)" price="60 000"  remark="true"/>  <br/>
                            <span>* - в стоимость входит брекет система, первая дуга и работа по ее установке на одну челюсть.</span>
                            <br/>  <br/>
                       
                            <PriceLine serviceName="Активация брекет системы/замена дуг на одну челюсть" price="2 500"/>
                            <br/>
                            <b>Элайнеры</b>    
                            <br/>
                            <PriceLine serviceName="Лечение с помощью элайнеров" price="150 000"  remark="true"/>
                            <span>* - точная цена определяется после виртуального моделирования и определения количества кап.</span>
                            <br/>
                            <br/>
                            <b>Дополнительные услуги:</b>
                            <PriceLine serviceName="Профессиональная гигиена полости рта" price="2 500"/>
                            <PriceLine serviceName="Отбеливание зубов" price="20 000"/>
                        </div>
                </div>
		</div>
	);
}

export default priceList