import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Store from './store/store.js';

import { StoreContext } from './contexts';

(async function() {

  const store = new Store();


  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(

   
      <React.StrictMode>
         <StoreContext.Provider value={ store }>
            <App />  
          </StoreContext.Provider>
      </React.StrictMode>
  
  );


})();;