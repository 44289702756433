import React from "react";
import './index.css';



const services = () => {
	return (
		<div className="services">
			<h3 className="w-100">Мои возможности:</h3>

			<div className="row w-100 m-0 p-0">
				<div className="col-6  col-s-12 m-0 p-0 column">
					<div className="col-12 m-auto mt-0 mb-1 p-2">	
						<div className="cardService cardServiceVERT w-100">	
							<div className="col-12">
									{/* <img src="./imgs/IMG_1800.jpg"/> */}
									<img alt=""   src="./imgs/chlid.jpg"/>
									{/* <img alt=""   src="./imgs/IMG_6426.jpg"/> */}
							</div>			
							<div className=" column m-0 col-12">
								<h3>ИСПРАВЛЕНИЕ ПРИКУСА В ДЕТСКОМ ВОЗРАСТЕ</h3>
								<ul>
									<li>Частичная брекет-система </li>
									<li>Пластинки</li>
									{/* <li>Трейнеры</li> */}
								</ul>
							</div>
						</div>
					</div>
					<div className="col-12 m-auto mb-0 mt-auto p-2">	
						<div className="cardService ">
							<div className="col-6 col-s-12 ">
								{/* <img src="./imgs/IMG_1796.jpg"/> */}
								<img  alt=""  src="./imgs/IMG_1797.jpg"/>

							</div>
							<div className=" column m-0 col-6 col-s-12">
								<h3>ЛЕЧЕНИЕ БЕЗ БРЕКЕТОВ</h3>
								<ul>
									<li className="pl-1 pr-1">Исправление прикуса при помощи элайнеров</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="col-6 col-s-12 m-0 p-0 column">
					<div className="col-12  ml-0 mr-auto p-2">
						<div className="cardService cardServiceVERT maxImg w-100">			
							<div className="w-100">
									<img alt=""  src="./imgs/adult.jpg"/>
							</div>	
							<div className=" column m-0 w-100">
								<h3>ИСПРАВЛЕНИЕ ПРИКУСА ВО ВЗРОСЛОМ ВОЗРАСТЕ</h3>
								<ul>
									<li>Сапфировые брекеты</li>
									<li>Керамические брекеты</li>
									<li>Металлические брекеты</li>
									{/* <li>Ортоимплантаты</li> */}
									<li>Подготовка к протезированию</li>
								</ul>
								
							</div>
						</div>
					</div>
				</div>
			</div>


			{/* <h3>Дополнительные услуги</h3> */}
			<div className="row w-100 m-0 p-0 ">
				<div className="col-6 col-s-12 ml-0 mr-auto p-2">
						<div className="cardService cardServiceVERT w-100">			
							<div className="w-100">
									<img alt=""  src="./imgs/IMG_17952.jpg"/>
							</div>	
							<div className=" column m-0 w-100">
								<h3>ПРОФЕССИОНАЛЬНАЯ ГИГИЕНА ПОЛОСТИ РТА</h3>
								{/* <span>в 4 этапа:</span>
								<ul>
									<li>1. Ультразвук</li>
									<li>2. Airflow</li>
									<li>3. Полировка профессиональной пастой</li>
									<li>4. Фторирование зубов</li>
								</ul> */}
							</div>
						</div>
					</div>
					
					<div className="col-6 col-s-12 ml-0 mr-auto p-2">
						<div className="cardService cardServiceVERT w-100">			
							<div className="w-100">
									<img alt=""  src="./imgs/whiteSmile.jpg"/>
							</div>	
							<div className=" column m-0 w-100">
								<h3>ОТБЕЛИВАНИЕ ЗУБОВ</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
	);
}

export default services