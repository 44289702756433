// import AppStore from './AppStore';


import NetworkTools from '../utils/NetworkTools';

import MainStore from './mainStore.js';

import HeaderStore from '../components/header/store.js';



export default class Store {
  constructor() {
    this.network = new NetworkTools(this);
    this.mainStore = new MainStore(this);
    this.headerStore = new HeaderStore(this);
  }
};
