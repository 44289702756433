import React from "react";
import './index.css';

import { Row } from "react-bootstrap";

const about = (props) => {

	const allCerts= ['cert1','IMG_6381','IMG_6376', 'IMG_6380', 'IMG_6373','cert2','IMG_6382', 'IMG_6377','IMG_6378','IMG_6379','IMG_8691'];
	return (
		<div className="about p-0 m-0 " > 
			<h3 col="12">Обо мне</h3>
			<div className="container ">
				<div className="col-8  col-s-12 p-1">
					<div className="cloud  p-0">
						{/* <img src="./imgs/pimy.jpg" className="pimy"/> */}
						<div className="column ml-0 ml-auto-mobile mr-auto p-1 z3">
							<h3>2+</h3> 
							<span>
								Законченных высших <br/>образовательных учреждения.
							</span>
							<div className="column m-0 p-1 w-100 z3">
								<div className="education-label lightred m-3 ">
									<b>ПИМУ</b>
									<label>г. Нижний Новгород</label>
								</div>
								<div className="education-label blue m-3">
									<b>МГМСУ</b>
									<label>г. Москва</label>
								</div>
							</div>
						</div>
						{/* <div className="w-40 ml-auto mr-0"> 
						</div> */}
						<div className="backgroupOpacity leftToRight" style={{
							height: '100%',
							width: '200px',
							left: '180px'
						}}>   </div>
						<img src="./imgs/background_second_block2.jpg" className="background_second_block hiddenInMobile"/>
					</div>
				</div>
				<div  className="col-4 ml-auto  mr-0 col-s-12 p-1" >
					<div className="cloud  column">
						<img src="./imgs/cabinet.jpg" className="cabinet hiddenInMobile"/>
						<img src="./imgs/mgmsuMobile.jpg" className="cabinet onlyInMobile"/>

		
						<div className="backgroupOpacity bottomToTop hiddenInMobile" style={{
							height: '200px',
							width: '110%',
							bottom: '0px',
							margin: '-12px'

						}}>   </div>

						<div className="backgroupOpacity bottomToTop onlyInMobile " style={{
							height: '200px',
							width: '110%',
							bottom: '0px',
							margin: '-12px'

						}}>   </div>
						<h3 className="z3 mt-auto">9+</h3> 
						<span className="z3">
							лет в стоматологии
						</span>
					</div>
				</div>
				<div  className="col-12 mrl-auto  col-s-12 p-1">
					<div className="cloud column ">
						<h3 className="">10+</h3> 
						<span>
							Курсов повышения квалификации
						</span>
						<div className="w-100 row scrollable">					
							{allCerts.map((cert, index) => {
								return (
									<img className="cert"  src={"./imgs/" + cert + "_preview.jpg"} onClick={props.openCert} original={cert} key={index}/>
										)})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default about