import React from "react";
import './index.css';



const contacts = () => {
	return (
		<div className="contacts column" id="contactBlock">
			<h3>Как со мной связаться:</h3>

			<span className="mlr-auto col-s-12 col-8">
				 Днем я работаю, а ночью восстанавливаю силы. Мне тяжело ответить на ваш звонок во время приема. <b>Лучше напишите мне сообщение</b>, а я точно отвечу в свободную минутку.
			</span>
			<h3>
				<a href="tel:+79877587777">+7-987-758-77-77</a>
			</h3>

			

			<div className="row">

				<a href="tg://resolve?domain=+79877587777" target="_blank">
					<img src="./imgs/icons8-telegram-96.png" className="icons"/>
				</a>

				<a href="https://api.whatsapp.com/send?phone=+79877587777&text=Добрый день."  target="_blank">
					<img src="./imgs/icons8-whatsapp-96.png" className="icons"/>
				</a>	

				<a href="viber://chat?number=%2B9877587777"  target="_blank">
					<img src="./imgs/viber-3.png" className="icons"/>
				</a>	
	
			</div>
		</div>
	);
}

export default contacts