import { makeAutoObservable, runInAction } from 'mobx';


export default class MainStore {

    curModalview  = false;
    curCert = false;
 

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
      }


    openCert = (ev) => {
        // alert('fgdsg'+ ev);
        this.curModalview = 'cert';
        this.curCert = ev.target.attributes.original.nodeValue;
    }
    closeCert = () => {
        this.curModalview = false;
        this.curCert = false;
    }
    openInfo = () => {
        this.curModalview = 'info'
    }
}
