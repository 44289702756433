import React from "react";
import './index.css';



const myTargets = () => {
	return (
		<div className="myTargets">
			<h3>Этапы ортодонтического лечения:</h3>
			<div className="container row" > 
					<div className="col-5 col-s-12">
						<div className="cloud">
							<b>1. Консультация</b>
							<span>Проводится опрос, осмотр, сбор анамнеза, изучается история болезни, анализ имеющихся рентгенологических исследований, предполагаемая схема лечения и возможные.</span>
						</div>
					</div>


					<div className="col-6 col-s-12">
						<div className="cloud">
							<b>2. Диагностика</b>
							<span>Диагностика включает в себя два визита. На первом посещении проводится сбор диагностических данных, а на втором составление индивидуального плана лечения.</span>
						</div>
					</div>

					<div className="col-6 col-s-12">
						<div className="cloud">
							<b>3. Лечение</b>
							<span>После принятия решения о начале лечения возможно приступать к его реализации согласно этапам и последовательности рекомендованного плана.</span>
						</div>
					</div>
					<div className="col-5 col-s-12">
						<div className="cloud">
							<b>4. Профилактический осмотр</b>
							<span>Для контроля состояния зубочелюстной системы после проведения лечения необходимо наблюдаться у лечащего врача 1 раз в
6 месяцев.</span>
						</div>
					</div>

		
			</div>
			<div className="container row" hidden> 
				<div className="col-12">
					<div className="cloud">
					<b>Качество и честность</b> вот базовый принцип на которых устраивают долгосрочные доверительные отношения с каждым пациентом
					{/* Мой главный принцип в работе - эффективность и безопасность. Ведь долгосрочные, доверительные отношения с пациентом очень важны. */}
					</div>
				</div>


				<div className="col-5 col-s-12">
					<div className="cloud">
						{/* Высококачественные материалы и профессиональные методы  */}
						<b>Профессиональный подход </b>к лечению, нацеленный на успешное решение проблемы.
					</div>
				</div>



				<div className="col-7  col-s-12">
					<div className="cloud">
						Обеспечение <b>комфорта и безопасности пациента</b> во время процедур. Гарантия стерильности инструмента.
					</div>
				</div>


				<div className="col-6  col-s-12">
					<div className="cloud">
						Выбор<b> проверенных методик</b>, исходя из критериев эффективности, а не финансовой выгоды.
					</div>
				</div>


				<div className="col-6  col-s-12">
					<div className="cloud">
						При работе использую исключительно <b>качественные препараты </b> и расходные материалы.
						Не применяю в работе дешевые аналоги от неизветсных производителей.
					</div>
				</div>

			</div>	
		</div>
	);
}

export default myTargets