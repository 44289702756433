import axios from 'axios';
import qs from 'qs'
import {nanoid} from 'nanoid';


const MessageVariants = {
  GREEN: 'success',
  YELLOW: 'warning',
  RED: 'danger'
};


const API_PREFIX = 'https://festapp.ru/api/v1_b2b'; //
// const API_PREFIX = 'https://festapp.ru/api/v1_b2b_dev'; // 
const curFestIDPath = 'fest/suzdal2024/';


const Methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put'
};

const NETWORK_ERROR_MESSAGE = 'Невозможно подключиться к серверу. Проверьте подключение и повторите попытку.';

const printErrorInConsole = (status, message) => {
  console.error(`status: ${status}\n\rmessage: ${message}`);
}

const printInfoInConsole = (status, message) => {
  console.error(`status: ${status}\n\rmessage: ${message}`);
}

class NetworkTools {
  constructor(rootStore) {
    this.rootStore = rootStore;
    axios.defaults.baseURL = API_PREFIX;
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';


  }
  
  _send = async (url, method, userData, useToken = false) => {
    
    const isBlob = userData instanceof Blob;
    this.rootStore.appStore.loadContainer = true;

    const config = {
      method: method, 
      url: url, 
      data: (isBlob) ? userData : qs.stringify(userData),
      headers: {
        'X-Authorization': (this.rootStore.appStore.token) ? this.rootStore.appStore.token : ''
      }
    };

    let resp;
    
    try {
       resp = await axios(config);
    } catch(err) {

      this.rootStore.appStore.loadContainer = false;
      this.rootStore.appStore.showMessage(NETWORK_ERROR_MESSAGE, MessageVariants.RED);
      
      return {
        status: false,
        data: false,
        message: false,
      };
    }

    let status = false;
    
    switch(resp.data.status) {
      case 200:
        resp.data.message && printInfoInConsole(resp.data.status, resp.data.message);
        status = true;
        break;
      case 270: 
        this.rootStore.appStore.showMessage(resp.data.message, MessageVariants.GREEN);
        status = true;
        break;
      case 271: 
        this.rootStore.appStore.showMessage(resp.data.message, MessageVariants.YELLOW);
        status = true;
        break;
      case 400: 
      resp.data.message && printErrorInConsole(resp.data.status, resp.data.message);
        status = false; 
        break;
      case 470: 
        this.rootStore.appStore.showMessage(resp.data.message, MessageVariants.RED);
        status = false;
        break;
      case 475: 
        this.rootStore.appStore.clearAppData();
        // this.rootStore.appStore.openAuthorizationPage();
        this.rootStore.appStore.showMessage(resp.data.message, MessageVariants.RED);
        status = false;
        break;
      default: 
        status = false;
        printErrorInConsole(resp.data.status, resp.data.message);
    }

    this.rootStore.appStore.loadContainer = false;
    return {
      status,
      data: resp.data.data,
      message: resp.data.message,
    };
  }



  checkEmailAuth = async (email) => {
    return await this._send(curFestIDPath + 'auth/' + email +'/check', Methods.GET);    
  }

   whatIsABrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return('Opera');
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      return('Edge');
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return('Chrome');
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return('Safari');
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return('Firefox');
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
    {
      return('IE');
    } else {
      return('unknown');
    }
  }

  checkToken = async () => {
    return await this._send(curFestIDPath + 'auth/'+ this.whatIsABrowser() + '/checkSession', Methods.GET);    
  }


}

export default NetworkTools;
